import {
  PAYMENT,
  PRODUCT,
  AUTH,
  HOME_SETTINGS,
  MY_ACCOUNT,
  EXPERIENCES_SETTINGS,
  LOADING,
  FAQ,
  HEADER,
  FOOTER,
  PLANS,
  SPECIAL_PLAN,
  EMBASSIES,
  XRAY,
  SEO,
  TERMS,
  CENSUS,
  EVENTS,
  KONDUTO,
  CHECKOUT,
  DISCOUNT_VALIDATOR,
  VIRTUAL_TICKETCARD,
  PARTNES,
  MY_PACKAGES
} from './configs';
import { IFengSettings } from './interfaces';

export const fengSettings: IFengSettings = {
  name: 'stfeng',
  version: '0.0.1',
  hmr: true,
  lightVersion: false,
  mockedCounter: 0,
  projectName: 'Tardezinha',
  clientName: 'Feng Sócio Torcedor',
  clientId: 'CNPJ 00.000.000/0001-00',
  clientPhone: '(21) 2222-2222',
  clientEmail: 'dev@fengbrasil.com.br',
  clientAddress: 'Rua Rodrigo Silva, 26 – 22º andar Centro | Rio de Janeiro – RJ, 20011-040, Brasil',
  cache: {
    strategy: 'explicit',
    localStorageKey: 'fengHttpCache',
    ttl: 60000 // 1min (60000) - 5min (300000) - 30min (1800000) - 30min (1800000) - 1h (3600000) - 2h (7200000) - 6h (21600000) - 12h (43200000) - 24h (86400000)
  },
  auth: AUTH,
  payment: PAYMENT,
  loading: LOADING,
  homeSettings: HOME_SETTINGS,
  myAccount: MY_ACCOUNT,
  partnersSettings: PARTNES,
  experiencesSettings: EXPERIENCES_SETTINGS,
  faqSettings: FAQ,
  headerSettings: HEADER,
  footerSettings: FOOTER,
  plans: PLANS,
  specialPlan: SPECIAL_PLAN,
  xRay: XRAY,
  embassies: EMBASSIES,
  checkout: CHECKOUT,
  events: EVENTS,
  product: PRODUCT,
  myPackages: MY_PACKAGES,
  discountValidator: DISCOUNT_VALIDATOR,
  virtualTicketCard: VIRTUAL_TICKETCARD,
  terms: TERMS,
  census: CENSUS,
  SEO: SEO,
  konduto: KONDUTO,
}


