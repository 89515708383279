import { IEvents } from '../interfaces';

export const EVENTS: IEvents = {
  shield: 'https://fengbrasil-gs.s3.amazonaws.com/prod/assets/images/st_logo.png',
  background: 'https://fengbrasil-gs.s3.sa-east-1.amazonaws.com/prod/assets/images/bg-ingressos-s.png',
  name: 'fengbrasil',
  template: 1,
  ticketing: 'SUPERINGRESSO', // Available Options: FCARD / MBILHETE / TICKETHUB / SUPERINGRESSO
  integration: {
    enableTicketBalance: false
  },
  enableHowManyTickets: true,
  showMyTickets: true,
  showMoreInfo: true,
  showTicketsClose: true,
  showRating: true,
  showHowManyTickets: true,
  imageMoreInformation: 'https://fluminensefc.s3.amazonaws.com/prod/assets/images/maisinfo-flu.png',
}
