import { ITerm } from '../interfaces';

export const TERMS: ITerm = {
  limitCardMessage: 'Caso não tenha esse limite disponível no cartão de crédito, somente o valor da parcela será cobrado todos os meses de maneira automática. Isso não altera nenhuma condição prevista nos termos de uso.',
  splitted: false,
  termsURL: {
    url: null,
    route: '/termos-e-politicas',
    pageTitle: null,
    contentKeywords: ['termo', 'adesão']
  },
  privacyURL: {
    url: null,
    route: '/termos-e-politicas',
    pageTitle: null,
    contentKeywords: ['termo', 'adesão']
  }
}
