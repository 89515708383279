import { IMyPackage } from '../interfaces';

export const MY_PACKAGES: IMyPackage = {
  texts: {
    myPackagesView: {
      noContent: 'NENHUM PRODUTO ENCONTRADO',
      title: 'SEUS PACOTES',
    },
  },
  filters: [
    { label: 'comprados', id: 'purchased', selected: true },
    { label: 'interesse', id: 'interests', selected: false },
    { label: 'encerrado', id: 'closed', selected: false },
  ]
}
