import { IProduct } from '../interfaces';

export const PRODUCT: IProduct = {
  texts: {
    productsView: {
      noContent: 'NENHUM PRODUTO ENCONTRADO',
      title: 'VEJA OS PRODUTOS DISPONÍVEIS',
      filters: {
        search: {
          label: 'Procure uma produto...'
        }
      },
      actionButton: {
        buy: 'Comprar produto',
        interest: 'Tenho Interesse',
      }
    },
    interestModal: {
      actionButton: {
        buy: 'COMPRAR PRODUTO',
        interest: 'TENHO INTERESSE',
      }
    },
  },
  interest: {
    sucess: {
      icon: 'fengi-check-circle',
      title: 'seu interesse foi realizado com sucesso',
      text: '',
    },
  },
  productsFilters: {
    type: [
      { value: 0, label: 'Todos', selected: true, disabled: false },
      { value: 1, label: 'Compra direta', selected: false, disabled: false },
      { value: 2, label: 'Interesse', selected: false, disabled: false },
    ],
  },
  checkout: {
    instructions: {
      bankSlipInstructions: [
        {
          text: 'Imprima o boleto e pague no banco.',
          icon: 'fengi-print-icon'
        },
        {
          text: 'Ou pague pela internet utilizando o código de barras do boleto.',
          icon: 'fengi-web-icon'
        },
        {
          text: 'O prazo de validade do boleto são 3 dias úteis.',
          icon: 'fengi-clock-icon'
        },
      ],
      pixInstructions: [
        {
          text: 'Acesse o app do seu banco e vá na área PIX.',
          icon: 'fengi-app-icon'
        },
        {
          text: 'Aponte sua câmera para o QR Code ou copie e cole o código.',
          icon: 'fengi-qrcode-icon'
        },
        {
          text: 'Conclua o pagamento através do app.',
          icon: 'fengi-touch-icon'
        },
      ]
    },
    processingPage: {
      enabled: true,
      title: 'Agora falta pouco! Seu pagamento está sendo processado.',
      message: 'Recebemos seu pedido. Em breve você receberá um e-mail com todos os detalhes.',
      icon: 'fengi-check-circle',
      buttons: [
        { text: 'SEUS PACOTES', type: 'primary', outline: false, event: '/meus-pacotes' },
        { text: 'PÁGINA DE PRODUTOS', type: 'ghost', outline: true, event: '/produtos' },
      ]
    },
    successPage: {
      enabled: true,
      title: 'Compra efetuada com Sucesso! Seu pagamento foi processado.',
      message: 'Seu pagamento foi realizado com sucesso! Em breve você receberá um e-mail com todos os detalhes.',
      icon: 'fengi-check-circle',
      buttons: [
        { text: 'SEUS PACOTES', type: 'primary', outline: false, event: '/meus-pacotes' },
        { text: 'PÁGINA DE PRODUTOS', type: 'ghost', outline: true, event: '/produtos' },
      ]
    },
    boletoPage: {
      enabled: true,
      title: 'BOLETO GERADO COM SUCESSO!',
      message: 'Agora falta pouco! Pague o seu boleto até a data de vencimento. Pagamento via Boleto, é compensado em até 3 dias após o pagamento.',
      icon: 'fengi-bar-code-icon',
      buttons: [
        { text: 'IMPRIMIR BOLETO', type: 'secondary', outline: false, event: 'print', icon: 'print-icon' },
      ]
    },
    pixPage: {
      enabled: true,
      title: 'Pague a fatura gerada através do QR Code abaixo.',
      message: 'Aponte seu telefone para a tela, ou copie o codigo PIX. O pagamento via PIX é compensado pouco tempo após o pagamento.',
      buttons: [
        { text: 'COPIAR CÓDIGO PIX', type: 'secondary', outline: false, event: 'copy', icon: 'fengi-pix' },
      ]
    },
    paymentFailPage: {
      enabled: true,
      title: 'Ocorreu um erro ao efetuar o seu pagamento!',
      icon: 'fengi-error-circle-icon',
      buttons: [
        { text: 'TENTAR NOVAMENTE', type: 'secondary', outline: false, event: 'retry' },
        { text: 'PÁGINA DE PRODUTOS', type: 'ghost', outline: true, event: '/produtos' },
      ]
    },
  },
}
