import { IHome } from '../interfaces';

export const HOME_SETTINGS: IHome = {
  scroll: {
    callToAction: {
      enable: true,
      template: 1,
      titleImage: '',
      titleText: 'XXX'
    },
    hero: false,
    tabs: {
      enable: false,
      template: 4
    },
    plans: {
      enable: true
    },
    specialPlans: {
      enabled: false
    },
    exclContent: false,
    testimony: false,
    callToActionFooter: false
  },
  cards: {
    enable: true,
    contentBox: true,
    experiences: false,
    plans: true,
    ticketCards: true,
    tickets: true,
    enableColorTheme: true
  },
  header: {
    legacyMsg: ``,
    templateHeader: 2,
    activeNewPlan: false,
    voucherMessage: 'PARABÉNS, VOCÊ GANHOU <strong>$MENGO</strong><br />BAIXE O APP E ATIVE O TOKEN '
  },
  enableContractStatusWarning: true
}
