import { IEmbassies } from '../interfaces';

export const EMBASSIES: IEmbassies = {
  template: 4,
  myEmbassyTemplate: 1,
  enableTabOpenValidation: true,
  showFanCounter: false,
  personTypes: {
    FENG20001: 'EMBAIXADAS',
  },
  embassiesPage: {
    beforeTableHTML: `
        <h1>Embaixadas <strong>Fengers</strong></h1>
        <p>
          O clube que ganhou o mundo segue sua expansão global para que torcedores de qualquer lugar possam viver de perto a emoção que é torcer com experiências exclusivas e toda diplomacia que merece.
        </p>
        <p>
          A criação das Embaixadas já começou e queremos ver este mapa repleto de representatividade para os torcedores de todos os lugares do mundo. Sabemos que nossa torcida é imensa e merece viver essa paixão de perto, mesmo que morando longe.
        </p>
      `,
    afterTableHTML: `
        <p>
          Você, que é de outro país ou estado brasileiro, pode unir fãs da sua região e fundar uma Embaixada. Desta forma, você será o responsável por representar um verdadeiro embaixador, levando benefícios e mediando junto ao seu clube de coração experiências exclusivas para todos que fizerem parte de sua Embaixada.
        </p>
        <!--<p>
            <strong>Clique aqui</strong> e saiba como fundar sua Embaixada!
        </p>-->
      `,
    inactiveEmbassyWarning: `
        <p>Sua embaixada foi desativada pela administração do clube. Por favor, entre em contato com a secretaria, no número (83) 3681-3801, para entender como você pode reativá-la..</p>
      `,
  },
  createEmbassy: {
    template: 'fngst-1',
    // agreementLabel: 'Eu concordo com os termos de uso da plataforma de criação de uma embaixada.'
    defaultMembersCount: 3,
    maxMembersInput: 10,
    requireMembersCPF: false,
    requireEmbassyPassword: false,
    requireMembersCPFWarning: '<p>Caso não queira adicionar membro agora você poderá fazer isso mais tarde através da tela administrativa de sua embaixada.<br />Para isso, basta excluir os campos clicando nas lixeiras.</p>',
    requireMembersCPFNoFieldMessage: '<p>Nenhum membro será adicianado no momento.<br />Faça isso mais tarde através da tela administrativa de sua embaixada ou clique no botão abaixo para criar novos campos.</p>',
    CPFNotFoundSubstring: 'CPF(s) não encontrado(s): ',
    BelongsToAnotherEmbassySubstring: 'Sócio(s) já cadastrado(s) a uma embaixada: ',
    modal: {
      success: {
        title: `
            <h3>Parabéns, você acaba de solicitar a criação de sua embaixada!</h3>
          `,
        description: `
            <p>Os seus primeiros membros iram receber uma notificação em suas contas e com isso poderemos dar continuidade aprovação e criação da embaixada.</p>
          `,
      },
      memberWarning: {
        title: `
            <h3>Esse(s) membro(s) já faz(em) parte de uma embaixada!</h3>
          `,
        description: `
            <p>Identificamos que o(s) CPF(s) {{MEMBERS_CPF}} já faz(em) parte de alguma embaixada. Cada sócio só pode pertencer a uma embaixada.</p>
          `,
      },
      notFound: {
        title: `
            <h3>Usuários não encontrados!</h3>
          `,
        description: `
            <p>Não identificamos os usuários abaixo:<br /> {{USERS}}</p>
          `,
      }
    }
  },
  howScorePage: {
    plans: [
      {
        title: 'VERMELHO',
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-plan-vermelho.png',
        periodicities: [{ 'PLANOS SEMETRAIS': 5 }, { 'PLANOS ANUAIS': 10 }],
      },
      {
        title: 'BRANCO',
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-plan-branco.png',
        periodicities: [{ 'PLANOS SEMETRAIS': 10 }, { 'PLANOS ANUAIS': 20 }],
      },
      {
        title: 'PRETO',
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-plan-preto.png',
        periodicities: [{ 'PLANOS SEMETRAIS': 20 }, { 'PLANOS ANUAIS': 40 }],
      },
      {
        title: 'TRICOLOR',
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-plan-tricolor.png',
        periodicities: [{ 'PLANOS SEMETRAIS': 33 }, { 'PLANOS ANUAIS': 66 }],
      },
      {
        title: 'DIAMENTE',
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-plan-diamante.png',
        periodicities: [{ 'PLANOS SEMETRAIS': 50 }, { 'PLANOS ANUAIS': 100 }],
      },
    ],
    otherWays: [
      {
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-partners-monthly.png',
        description:
          'EmbaixmyAccounta que mais trouxer sócios no <strong>mês</strong>',
        points: 500,
      },
      {
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-partners-annual.png',
        description:
          'Embaixada que mais trouxer sócios no <strong>ano</strong>',
        points: 3000,
      },
      {
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-growth-monthly.png',
        description:
          'Embaixada com maior crescimento <strong>mensal</strong>',
        points: 500,
      },
      {
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-growth-annual.png',
        description:
          'Embaixada com maior crescimen to <strong>anual</strong>',
        points: 3000,
      },
      {
        icon: 'https://spfc-gs.s3.amazonaws.com/prod/assets/icons/icon-embassy-donations.png',
        description: 'Realização de <strong>ações sociais</strong>',
        points: 200,
      },
    ],
  },
}
