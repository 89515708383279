export const fengEnv = {
  production: false,
  maintenance: false,
  // apiURL: 'http://localhost:4100/',
  apiURL: 'https://api.dev.st.feng.rocks/v1/',
  weURL: 'https://we.dev.st.feng.rocks/',
  recaptcha: {
    key: '6LduwiIoAAAAAFkkYchu4Qy3W2ch2sCkmLCBH6XZ',
  },
  konduto: {
    public_key: 'TB3EDE87884'
  },
  auth: {
    new: false,
    social: [
      // { name: 'google', provider: '' },
      // { name: 'facebook', provider: '' }
    ],
    cognito: {
      enable: false,
      enableSSO: false,
      REGION: '',
      USER_POOL_ID: '',
      APP_CLIENT_ID: '2',
      FRONT_URL: 'http://localhost:4200',
      SSO_ENDPOINT: 'https://auth.dev.st.feng.rocks',
      FBANK_CLIENT_ID: '',
      FBANK_AUTH_TOKEN: ''
    }
  },
  activeProject: 'TARDEZINHA'
};
