import { IFAQ } from '../interfaces';

export const FAQ: IFAQ = {
  faqTemplate: 8,
  mockFaq: false,
  contacts: {
    chat: {
      active: true,
      availability: 'de segunda a sexta, das 8h às 21h, e aos sábados, das 10h às 16h',
    },
    email: {
      active: true
    }
  }
}
