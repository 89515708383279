import { IPayment } from '../interfaces';

export const PAYMENT: IPayment = {
  // template: 2,
  creditCard: {
    maxAllowed: 3,
    allowedTypes: [
      { type: 'visa', regex: new RegExp('^4') },
      { type: 'mastercard', regex: new RegExp('^5[1-5]') },
      { type: 'american-express', regex: new RegExp('^3[47]') },
      { type: 'diners', regex: new RegExp('^30[0-5]') },
      { type: 'jcb', regex: new RegExp('^35(2[89]|[3-8][0-9])') },
      { type: 'visa-electron', regex: new RegExp('^(4026|417500|4508|4844|491(3|7))') },
      { type: 'maestro', regex: new RegExp('^(5000|5018|5020|5038|6304|6759|676[1-3])') },
      { type: 'discover', regex: new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)') },
      { type: 'elo', regex: new RegExp('^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|655000|655001|651652|651653|651654|650485|650486|650487|650488|506699|5067[0-6][0-9]|50677[0-8]|509\d{3})') },
      { type: 'hipercard', regex: new RegExp('^(606282\d{10}(\d{3})?|3841\d{15})') },
    ],
    enableCaptcha: true,
    enableDelete: true
  },
  installments: {
    multiplePayment: {
      creditCardAllowed: true,
      bankSlipAllowed: true,
      pixAllowed: false,
    },
    subdivision: {
      creditCard: false,
    }
  },
}
