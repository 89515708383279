import { IXray } from '../interfaces';

export const XRAY: IXray = {
  enableCounter: true,
  enableTabBackgroundColor: true,
  enableStates: false,
  enableCities: false,
  enableDistricts: false,
  enablePlans: true,
  enablePaymentMethod: true,
  enableAge: true,
  enableSex: true,
  enableXRay: true,
  enableXRayActionBackgroundColor: true,
  featuredTemplate: 2,
  enableFinancial: false,
  financialTemplate: 2,
  enableTransparency: false,
  tranparencyMonthsNameType: 'fullName', // fullName | shortName,
}
