import { IAuth } from '../interfaces';

export const AUTH: IAuth = {
  enableAdmin: true,
  enableSt: true,
  enableResale: false,
  enableCaptcha: true,
  verifyCPF: false,
  verifyEmail: true,
  keepConnected: true,
  admin: {
    applyTokenIn: ['auth']
  },
  loginForm: {
    title: 'Para primeiro acesso ao novo sistema, clique abaixo em Gerar nova senha para receber no seu e-mail cadastrado.',
    text: 'Email/CPF',
    forgotPass: 'Gerar nova senha',
    forgotPassText: 'Preencha com o seu e-mail ou CPF cadastrado no Sócio Feng e enviaremos um código de recuperação para você. O e-mail só será enviado caso o cadastro seja encontrado.',
    forgotEmailText: 'Caso você tenha esquecido o seu e-mail, entre em contato com o atendimento <a href="/atendimento">clicando aqui</a>.',
    forgotAccountLabel: 'E-mail/CPF'
  },
  firstAccess: {
    enable: false,
    migrationLogic: false,
    ask: {
      password: false,
      terms: true,
      missingData: true
    }
  },
  social: [
    { name: 'google', enabled: false },
    { name: 'facebook', enabled: false }
  ],
  register: {
    enableSt: true,
    showTerms: true,
    fields: {
      genre: false,
      clubId: false,
    },
    clubIdValidator: ''
  },
  clearSale: {
    // https://github.com/code-farmz/ng-otp-input
    // https://www.npmjs.com/package/ng-otp-input
    enable: false,
    successMessage: 'Cadastro validado.',
    OTPConfig: {
      allowNumbersOnly: true,
      length: 6,
      isPasswordInput: false,
      disableAutoFocus: false,
      placeholder: "",
      inputStyles: {
        width: "40px",
        height: "50px",
      },
      resendTokenWaitTimeInSeconds: 60,
    },
    loadingStatus: {
      score: 'Estamos validando algumas informações.<br />Por favor, não feche e não recarregue a página.',
      backgroundCheck: 'Para sua segurança estamos validando seus dados.<br />Por favor, não feche e não recarregue a página.',
      getSms: 'Solicitando token para confirmar número de telefone.<br />Por favor, não feche e não recarregue a página.',
      sms: 'Validando o token.<br />Por favor, não feche e não recarregue a página.',
    }
  },
  staging: {
    enable: false,
    title: 'SEJA BEM-VINDO!',
    subtitle: '',
    description: `
        <p>
          Bloqueio de navegação para ambiente de staging <strong>em fase de teste</strong>.
        </p>
        <p>
          Para liberar navegação, adicione <strong>/login</strong> no final da URL desta página
          e efetue o login com um <strong>usuário administrativo</strong> com
          <strong>perfil 'Administrador', 'Desenvolvedor' ou 'Staging'.
        </p>
        <p>
          <strong>Após validação da funcionalidade, esta mensagem será removida.</strong>
        </p>
      `,
  },
}
