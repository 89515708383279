import { ICheckout } from '../interfaces';

export const CHECKOUT: ICheckout = {
  register: {
    promptValidation: {
      cpf: false,
      email: false,
      clubId: false,
    }
  },
  defaultDuration: '12',
  maxGuests: true,
  showPaymentMethods: true,
  comingSoonPaymentSlip: false,
  modalSixPeriodicity: {
    enabled: false,
    title: 'ATENÇÃO',
    message: `
        Os planos com duração semestral não possuem o benefício do kit de boas-vindas.
        Você receberá apenas a carteirinha. Caso deseje receber o kit, altere a periodicidade para Anual.
      `
  },
  offState: {
    state: 'RJ',
    modalOffState: {
      enabled: false,
      title: 'ATENÇÃO',
      message: `
          Você selecionou um plano fora do estado do RS, iremos te redirecionar para página de planos para selecionar o plano correto.
        `
    }
  },
  instructions: {
    bankSlipInstructions: [
      {
        text: 'Imprima o boleto e pague no banco.',
        icon: 'fengi-print-icon'
      },
      {
        text: 'Ou pague pela internet utilizando o código de barras do boleto.',
        icon: 'fengi-web-icon'
      },
      {
        text: 'O prazo de validade do boleto são 3 dias úteis.',
        icon: 'fengi-clock-icon'
      },
    ],
    pixInstructions: [
      {
        text: 'Acesse o app do seu banco e vá na área PIX.',
        icon: 'fengi-app-icon'
      },
      {
        text: 'Aponte sua câmera para o QR Code ou copie e cole o código.',
        icon: 'fengi-qrcode-icon'
      },
      {
        text: 'Conclua o pagamento através do app.',
        icon: 'fengi-touch-icon'
      },
    ]
  },
  processingPage: {
    enabled: true,
    title: 'Agora falta pouco! Seu pagamento está sendo processado.',
    message: 'Recebemos seu pedido. Em breve você receberá um e-mail com todos os detalhes.',
    icon: 'fengi-check-circle',
    buttons: [
      { text: 'SEUS PACOTES', type: 'primary', outline: false, event: '/meus-pacotes' },
      { text: 'PÁGINA DE PRODUTOS', type: 'ghost', outline: true, event: '/produtos' },
    ]
  },
  successPage: {
    enabled: true,
    title: 'Pagamento processado!',
    message: `Seja bem vindo ao Sócio FengST.`,
    icon: 'fengi-check-circle',
    buttons: [
      { text: 'SEUS PACOTES', type: 'primary', outline: false, event: '/meus-pacotes' },
      { text: 'PÁGINA DE PRODUTOS', type: 'ghost', outline: true, event: '/produtos' },
    ]
  },
  boletoPage: {
    enabled: true,
    title: 'Boleto gerado com sucesso!',
    message: `Agora falta pouco! Pague o seu boleto até a data de vencimento.`,
    icon: 'fengi-bar-code-icon',
    buttons: [
      { text: 'IMPRIMIR BOLETO', type: 'secondary', outline: false, event: 'print', icon: 'print-icon' },
    ]
  },
  pixPage: {
    enabled: true,
    title: 'Código PIX gerado com sucesso',
    message: `Agora falta pouco! Pague a fatura gerada através do QR Code abaixo.`,
    buttons: [
      { text: 'COPIAR CÓDIGO PIX', type: 'secondary', outline: false, event: 'copy', icon: 'fengi-pix' },
    ]
  },
  paymentFailPage: {
    enabled: true,
    title: 'Ocorreu um erro ao efetuar o seu pagamento!',
    message: `Não foi possível processar o seu pagamento na forma de pagamento selecionada.`,
    icon: 'fengi-error-circle-icon',
    buttons: [
      { text: 'TENTAR NOVAMENTE', type: 'secondary', outline: false, event: 'retry' },
      { text: 'PÁGINA DE PRODUTOS', type: 'ghost', outline: true, event: '/produtos' },
    ]
  },
  enableValidator: false,
  upgrade: {
    enableDiscountRule: true
  }
}
