import { IHeader } from '../interfaces';

export const HEADER: IHeader = {
  headerTemplate: 5,
  floatingLogin: false,
  enableCounter: true,
  hideCounterOnPage: ['/transparencia'],
  enableMobileMenu: true,
  hidePoints: true,
  profileImgRedirect: 'minha-conta/meu-plano',
  nav: [
    { title: 'HOME', routerLink: '/home', routerLinkActiveOptions: { exact: true } },
    { title: 'MINHA CONTA', routerLink: '/minha-conta/meu-plano', routerLinkActiveOptions: { exact: false } },
    { title: 'PLANOS', routerLink: '/planos', routerLinkActiveOptions: { exact: true } },
    { title: 'PRODUTOS', routerLink: '/produtos', routerLinkActiveOptions: { exact: false } },
    { title: 'MEUS PACOTES', routerLink: '/meus-pacotes', routerLinkActiveOptions: { exact: false } },
    { title: 'INGRESSOS', routerLink: '/ingressos', routerLinkActiveOptions: { exact: true } },
    { title: 'EMBAIXADAS', routerLink: '/embaixadas', routerLinkActiveOptions: { exact: true } },
    { title: 'ATENDIMENTO', routerLink: '/atendimento', routerLinkActiveOptions: { exact: true } },
    { title: 'MINHA EMBAIXADA', routerLink: '/minha-embaixada', routerLinkActiveOptions: { exact: true } },
    { title: 'EXPERIÊNCIAS', routerLink: '/experiencias', routerLinkActiveOptions: { exact: false } },
  ],
  activeSecondaryNavMenu: false,
  primaryNavMenuMinItens: 2, // por conta do menu Embaixadas
  moreItensButtonHTML: 'MAIS &nbsp; <i class="fengi-plus-circle"></i>',
  secondaryNavItens: [
    'ATENDIMENTO',
    'MINHA EMBAIXADA',
    'EXPERIÊNCIAS',
  ],
}
