import { IMyAccount } from '../interfaces';

export const MY_ACCOUNT: IMyAccount = {
  home: {
    showBannerNewLink: true
  },
  menu: {
    plan: true,
    profile: true,
    payment: true,
    dependents: false,
    chairs: true,
    myKit: true
  },
  profile: {
    partnershipPeriod: {
      withoutInterruption: false,
      firstContract: true,
    },
    hidePartneshipPeriod: false,
    complementary: {
      enabled: true,
      fields: {
        nationality: true,
        rg: true,
        civilStatus: true,
        education: true,
        residentialPhone: true,
        shirtSize: true,
        shortSize: true,
        shoeSize: true,
        income: false,
        interest: false,
        matchFrequency: true,
        hasChildren: true,
        childrenNumber: true,
        profession: false,
        company: false,
        interestWorkField: false,
        club: false,
        religion: true
      },
      options: {
        shirtSize: [
          { value: 'P', label: 'P' },
          { value: 'M', label: 'M' },
          { value: 'G', label: 'G' },
          { value: 'GG', label: 'GG' },
          { value: '3G', label: '3G' },
          { value: '4G', label: '4G' },
          { value: '7G', label: '7G' },
        ]
      }
    }
  },
  ticketCards: {
    textWarn: {
      enableForm: false,
      textInfo: `<strong>ATENÇÃO</strong> - Ative sua carteirinha clicando no botão abaixo <strong>apenas</strong> se já tiver com ela em mãos para usufruir dos benefícios`
    }
  },
  socialNetworks: {
    enabled: true
  },
  address: {
    enableComplementRequired: true,
    enableLimitCharacter: true,
    disabledCountry: true,
    bairroMaxLength: 45,
    cepMaxLength: 10,
    cidadeMaxLength: 45,
    estadoMaxLength: 2,
    logradouroMaxLength: 60,
    addressComplementLength: 20,
    addressReference: true,
  },
  myKit: {
    enabled: true,
    withdrawalAddress: 'Praça Roberto Gomes Pedrosa, 1, Morumbi – São Paulo CEP: 05653-070',
    modal: {
      title: 'Informações de Retirada',
      content: '<p>ST, você optou por retirar o kit em nossa casa, no MorumBIS! <br> A retirada acontece entre os dias YY/ YY e YY/ YY, das XXh às XXh.</p> <p>Atenção, ST! Em dias de partidas do São Paulo no MorumBIS, não há retirada de kit!</p>'
    },
    card: {
      title: 'O SPFC agradece o seu apoio!',
      content: 'Tricolor, a sua renovação de contrato do plano foi identificada. Em reconhecimento ao apoio, você acaba de ganhar um kit físico de Sócio Torcedor.'
    },
    cardInfo: {
      title: 'Em breve, o kit estará em suas mãos!',
      content: 'Agora que já selecionou a sua opção de retirada do kit, é só aguardar a data informada para recebê- lo e viver ainda mais a emoção de ser tricolor.'
    },
    modalCta: {
      title: 'O SPFC agradece o seu apoio!',
      content: 'Tricolor, a sua renovação de contrato do plano foi identificada. Em reconhecimento ao apoio, você acaba de ganhar um kit físico de Sócio Torcedor.'
    }
  }
}
