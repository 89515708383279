import { IPlan } from '../interfaces';

export const PLANS: IPlan = {
  template: 8,
  especial: true,
  cardPlaceholder: "https://ngx-feng.s3-sa-east-1.amazonaws.com/images/loading/img-placeholder.png",
  enableDependents: false,
  defaultsubscribeText: 'EU QUERO',
  customSubscribeText: 'QUERO ESTE!'
}
