import { IExperiencesSettings } from '../interfaces';

export const EXPERIENCES_SETTINGS: IExperiencesSettings = {
  version: 'v1',
  menu: {
    catalog: true,
    howToScore: true,
    points: true,
    rescued: true,
  },
  points: {
    enabledExperiences: false,
    enabledLoyalty: false,
    enabledFilterCategories: false,
    cardTemplate: 'fngst-1',
  },
  embassies: {
    enableFilter: true,
  },
  enableSocioClub: false,
  queueRetryLimit: 12,
  experienceV2: {
    enabled: true,
    status: {
      opened: true,
      futures: true,
      closed: true,
      nextEvents: true,
    },
    submenuItems: [
      { label: 'O que é', route: '/experiencias/o-que-e' },
      { label: 'Catálogo', route: '/experiencias/catalogo' },
      { label: 'Como pontuar', route: '/experiencias/como-pontuar' },
      { label: 'Extrato', route: '/experiencias/extrato' },
      { label: 'Resgatados', route: '/experiencias/resgatados' }
    ],
    catalog: {
      ctaText: 'Não perca tempo e participe de experiências rubro-negras.',
      info: {
        image: 'https://flamengo.s3-sa-east-1.amazonaws.com/prod/assets/images/st-tickets-stadium-with-bg.jpg',
        tag: 'EXPERIÊNCIA',
        title: 'Jogue no Maraca',
        description: 'Acumule pontos até novembro, para poder viver um momento único no templo do futebol. Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
        openingDetails: 'Abertura da experiência: <strong>14/03/24</strong>'
      },
    },
    landing: {
      videoScroll: [
        {
          title: 'Lectus auctor sodales',
          subtitle: 'Dignissim magna et et leo magna elementum adipiscing sit.',
          description: 'Dignissim magna et et leo magna elementum adipiscing sit. Eget dictum tortor eget id enim nisl. Amet dis nunc erat aliquet. In quam lorem id placerat cursus maecenas laoreet et eu. Amet eu neque natoque sit.',
          thumbnail: 'https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/img-catalogo-01.png',
          link: 'https://www.youtube.com/live/IaVSZ7gc8Fg?si=9cbQKqNjk9o9OMjM'
        },
        {
          title: 'Lectus auctor sodales',
          subtitle: 'Dignissim magna et et leo magna elementum adipiscing sit.',
          description: 'Dignissim magna et et leo magna elementum adipiscing sit. Eget dictum tortor eget id enim nisl. Amet dis nunc erat aliquet. In quam lorem id placerat cursus maecenas laoreet et eu. Amet eu neque natoque sit.',
          thumbnail: 'https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/img-catalogo-01.png',
          link: 'https://www.youtube.com/live/IaVSZ7gc8Fg?si=9cbQKqNjk9o9OMjM'
        },
        {
          title: 'Lectus auctor sodales',
          subtitle: 'Dignissim magna et et leo magna elementum adipiscing sit.',
          description: 'Dignissim magna et et leo magna elementum adipiscing sit. Eget dictum tortor eget id enim nisl. Amet dis nunc erat aliquet. In quam lorem id placerat cursus maecenas laoreet et eu. Amet eu neque natoque sit.',
          thumbnail: 'https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/img-catalogo-01.png',
          link: 'https://www.youtube.com/live/IaVSZ7gc8Fg?si=9cbQKqNjk9o9OMjM'
        }
      ],
      catalogScroll: [
        {
          title: 'Lectus auctor sodales',
          subtitle: 'Dignissim magna et et leo magna elementum adipiscing sit.',
          description: 'Dignissim magna et et leo magna elementum adipiscing sit. Eget dictum tortor eget id enim nisl. Amet dis nunc erat aliquet. In quam lorem id placerat cursus maecenas laoreet et eu. Amet eu neque natoque sit.',
          thumbnail: 'https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/img-catalogo-01.png',
        },
        {
          title: 'Lectus auctor sodales',
          subtitle: 'Dignissim magna et et leo magna elementum adipiscing sit.',
          description: 'Dignissim magna et et leo magna elementum adipiscing sit. Eget dictum tortor eget id enim nisl. Amet dis nunc erat aliquet. In quam lorem id placerat cursus maecenas laoreet et eu. Amet eu neque natoque sit.',
          thumbnail: 'https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/img-catalogo-01.png'
        },
        {
          title: 'Lectus auctor sodales',
          subtitle: 'Dignissim magna et et leo magna elementum adipiscing sit.',
          description: 'Dignissim magna et et leo magna elementum adipiscing sit. Eget dictum tortor eget id enim nisl. Amet dis nunc erat aliquet. In quam lorem id placerat cursus maecenas laoreet et eu. Amet eu neque natoque sit.',
          thumbnail: 'https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/img-catalogo-01.png'
        },
        {
          title: 'Lectus auctor sodales',
          subtitle: 'Dignissim magna et et leo magna elementum adipiscing sit.',
          description: 'Dignissim magna et et leo magna elementum adipiscing sit. Eget dictum tortor eget id enim nisl. Amet dis nunc erat aliquet. In quam lorem id placerat cursus maecenas laoreet et eu. Amet eu neque natoque sit.',
          thumbnail: 'https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/img-catalogo-01.png'
        },
        {
          title: 'Lectus auctor sodales',
          subtitle: 'Dignissim magna et et leo magna elementum adipiscing sit.',
          description: 'Dignissim magna et et leo magna elementum adipiscing sit. Eget dictum tortor eget id enim nisl. Amet dis nunc erat aliquet. In quam lorem id placerat cursus maecenas laoreet et eu. Amet eu neque natoque sit.',
          thumbnail: 'https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/img-catalogo-01.png'
        }
      ],
      howScroll: [
        {
          icon: 'fengi-icon-estadio',
          title: 'Como participar',
          description: 'Ao aderir a um plano, todos os sócios-torcedores estarão, automaticamente, aptos a participarem. Não é necessário nenhum cadastro extra!',
        },
        {
          icon: 'fengi-experience-medal',
          title: 'Acumule pontos',
          description: 'Você ganha pontos pela adimplência, compra de ingressos, renovação e upgrade do plano. Quanto maior o seu plano, mais pontos você ganha!',
        },
        {
          icon: 'fengi-icon-conteudo-prioridade',
          title: 'Troque seus pontos',
          description: 'Seus pontos podem ser trocados por experiências rubro-negras incríveis, para que você viva o Flamengo mais de perto. Confira o catálogo completo!',
        },
        {
          icon: 'fengi-exchange-points',
          title: 'Como trocar meus pontos',
          description: 'Acesse o nosso catálogo completo de experiências para verificar a disponibilidade e a quantidade de pontos necessários para efetuar o resgate da experiência que você deseja viver com o Flamengo.',
        }
      ]
    }
  }
}
