import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';

import { AuthAPIService, AppService } from '@fengbrasil/ngx-fengst-auth';
import { FGMeta, LoadingService, SEOService } from '@fengbrasil/ngx-fengst-ui';
import { ResizeService } from '@fengbrasil/ngx-fengst-ui';
import { combineLatest, Observable, ObservableInput, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { KondutoService } from '@fengbrasil/ngx-fengst-security';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let Konduto: () => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private subsink = new SubSink();

  public isHome: Observable<boolean> = this.appService.isHome$;
  public isDependent: Observable<boolean> = this.appService.isDependent$;
  public isAuth: Observable<boolean> = this.authService.isAuth$['st'];
  public isAuthAdmin: Observable<boolean> = this.authService.isAuth$['admin'];
  public isAuthStaging: Observable<boolean> = this.authService.isAuth$['staging'];
  public isResale: Observable<boolean> = this.authService.isAuth$['resale'];
  public isLoading: Observable<boolean> = this.loadingService.isLoading$;

  title = 'ngx-feng';

  constructor(
    private resizeService: ResizeService,
    private loadingService: LoadingService,
    private authService: AuthAPIService,
    private appService: AppService,
    private router: Router,
    private kondutoService: KondutoService,
    @Inject('fengEnv') public fengEnv: any,
    @Inject('fengSettings') public fengSettings: any
) { }

@HostListener('window:resize', ['$event'])
onResize(event: any) {
event.target.innerWidth;
this.resizeService.setSize(event.target.innerWidth);
}

ngOnInit(): void {

    if(this.fengSettings?.konduto?.active){
        this.kondutoService.install();
    }
    
    this.resizeService.setSize(window.innerWidth);
    // https://stackoverflow.com/a/48362223

    this.subsink.add(
        this.router.events
        .pipe(
            filter((event) => event instanceof NavigationEnd)
        )
        .subscribe((event: any) => {
            if(event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
                this.appService.mainChecks(event.url);
            }
            
        })
    )        
}

onRouterOutletActivate(e: any) {
}

}
