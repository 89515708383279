import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, BenefitResearchGuard, EmbassyGuard, GuestGuard, MaintenanceGuard, NotAdminGuard, NotMaintenanceGuard, NotStagingGuard, PendingEmailGuard, PendingGuard, StagingGuard } from '@fengbrasil/ngx-fengst-auth';
import { CensusAuthGuard, RegisterConfirmGuard } from '@fengbrasil/ngx-fengst-census';
import { AdminComponent, DesignSystemComponent, InternalServerErrorComponent, LogoutComponent, MaintenanceComponent, PageNotFoundComponent, RecoveryPasswordComponent, ScailingMessageComponent, StagingComponent, TermsComponent } from '@fengbrasil/ngx-fengst-layout';


const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, GuestGuard, MaintenanceGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePrivateModule),
    canActivate: [StagingGuard, AuthGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'home/:page',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'minha-conta/meu-plano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PlanModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/meu-perfil',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProfileModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/pagamentos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/dependentes',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.DependentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/sorteios',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.RafflesModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'minha-conta/cadeiras',
    loadChildren: () => import('@fengbrasil/ngx-fengst-account').then(m => m.ChairsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'minha-conta/meu-kit',
    loadChildren: () => import('@fengbrasil/ngx-fengst-account').then(m => m.MyKitModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard]
  },
  {
    path: 'carrinho',
    redirectTo: 'checkout/register',
    pathMatch: 'full',
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'checkout/register',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'checkout/validate',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ClearSaleModule),
    canActivate: [StagingGuard, MaintenanceGuard, BenefitResearchGuard],
  },
  {
    path: 'checkout/success',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'checkout/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'checkout/resubscribe/:idplano/:idcontrato',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeContractModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'checkout/upgrade/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.UpgradeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'checkout/address',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingGuard, PendingEmailGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'checkout/cart/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'checkout/cart',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'produtos',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ProductModule),
    canActivate: [StagingGuard, MaintenanceGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'checkout' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'meus-pacotes',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.MyPackagesModule),
    canActivate: [StagingGuard, MaintenanceGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'product' },
        { name: 'kdt:product', content: 'sku=123, name=meus-pacotes' },
      ],
    }
  },
  {
    path: 'planos',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansModule),
    canActivate: [StagingGuard, MaintenanceGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'product' },
        { name: 'kdt:product', content: 'sku=123, name=planos' },
      ],
    }
  },
  {
    path: 'conteudo-exclusivo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-exclusive-content').then((m) => m.MainModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'parceiros',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-partner').then((m) => m.PartnerModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'pendente',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-auth').then((m) => m.PendingModule),
    canActivate: [StagingGuard, AuthGuard],
  },
  {
    path: 'embaixadas',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-embassies').then((m) => m.EmbassiesModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'minha-embaixada',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-embassies').then((m) => m.MyEmbassyModule),
      canActivate: [AuthGuard, MaintenanceGuard, EmbassyGuard],
  },
  {
    path: 'embaixadas/crie-a-sua',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-embassies').then((m) => m.CreateEmbassyModule),
      canActivate: [AuthGuard, MaintenanceGuard],
  },
  {
    path: 'validar-cadastro/:id',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ValidateAccountModule),
    canActivate: [MaintenanceGuard],
  },
  // {
  //   path: 'experiencias',
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2AboutModule),
  //   canActivate: [MaintenanceGuard],
  // },
  // {
  //   path: 'experiencias/catalogo',
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2CatalogModule),
  //   canActivate: [MaintenanceGuard, AuthGuard],
  // },
  // {
  //   path: 'experiencias/como-pontuar',
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2HowModule),
  //   canActivate: [MaintenanceGuard, AuthGuard],
  // },
  // {
  //   path: 'experiencias/extrato',
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2PointsModule),
  //   canActivate: [MaintenanceGuard, AuthGuard],
  // },
  // {
  //   path: 'experiencias/resgatados',
  //   loadChildren: () =>
  //     import('@fengbrasil/ngx-fengst-experience').then((m) => m.V2RescuesModule),
  //   canActivate: [MaintenanceGuard, AuthGuard],
  // },
  {
    path: 'experiencias',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.CatalogModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/como-pontuar',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.HowModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/extrato',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.PointsModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'experiencias/resgatados',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-experience').then((m) => m.RescuesModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingGuard, PendingEmailGuard],
  },
  {
    path: 'ingressos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-events').then((m) => m.EventsMainModule),
      canActivate: [StagingGuard, MaintenanceGuard, PendingGuard],
  },
  {
    path: 'atendimento',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-contact').then((m) => m.ContactModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'censo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-census').then((m) => m.AuthModule),
      canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'censo/cadastro',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-census').then((m) => m.RegisterModule),
      canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'censo/confirmacao-cadastro',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-census').then((m) => m.RegisterConfirmModule),
      canActivate: [StagingGuard, MaintenanceGuard, CensusAuthGuard, RegisterConfirmGuard],
  },
  {
    path: 'censo/pesquisa',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-census').then((m) => m.ResearchModule),
      canActivate: [StagingGuard, MaintenanceGuard, CensusAuthGuard, RegisterConfirmGuard],
  },
  {
    path: 'termos-e-politicas',
    component: TermsComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [NotAdminGuard],
  },
  {
    path: 'staging',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'staging/login',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'sso',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'recuperar-senha/:id',
    component: RecoveryPasswordComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
    data: {
      meta: [
        { name: 'kdt:page', content: 'password-reset' },
      ],
    }
  },
  {
    path: 'design-system',
    component: DesignSystemComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'sair',
    component: LogoutComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'alta-demanda',
    component: ScailingMessageComponent
  },
  {
    path: '500',
    component: InternalServerErrorComponent
  },
  {
    path: 'manutencao',
    component: MaintenanceComponent,
    canActivate: [NotMaintenanceGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
