import { ICensus } from '../interfaces';

export const CENSUS: ICensus = {
  showProgressBar: true,
  OTPConfig: {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      "max-width": "40px",
      "min-width": "10px",
      "height": "60px",
    },
  },
  status: {
    loading: 'Carregando...',
    validatingToken: 'Validando token...',
  },
  benefits: true,
}
