import { IVirtualCard } from '../interfaces';

export const VIRTUAL_TICKETCARD: IVirtualCard = {
  template: 1,
  enable: false,
  showVirtualTicketCard: false,
  openVirtualTicketText: 'Visualizar Carteirinha Digital',
  imgLogo: {
    url: ''
  }
}
