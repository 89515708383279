export const fengTheme = {
    name: 'feng',
    properties: {

        '--background': '#FFFFFF',
        '--on-background': '#000',
        '--primary': '#ff5502',
        '--primary-light': 'white',
        '--primary-RGB': '24, 129, 95',
        '--on-primary': '#fff',
        '--secondary': '#F36F22',
        '--secondary-RGB': '186, 148, 15',
        '--on-secondary': '#fff',
        '--surface': '#fff',
        '--surface-RGB': '255, 255, 255',
        '--on-surface': '#000',
        '--error': '#F25455',
        '--error-RGB': '242, 84, 85',
        '--on-error': '#fff',
        '--warning': '#EFC845',
        '--warning-RGB': '239, 200, 69',
        '--on-warning': '#fff',
        '--success': '#68DB6C',
        '--success-RGB': '104, 219, 108',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--info-RGB': '208, 208, 208',
        '--on-info': '#fff',

        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'Roboto',
        '--font-title': 'Kufam',
        '--font-button': 'Roboto',
        '--font-event': 'Roboto',

        // Menu
        '--menu-itens-color': '--primary-light',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': 'white',
        '--menu-active-background': '#946CB1',
        '--menu-active-underline': '0',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        // '--header-bg': 'url(https://fluminensefc.s3.amazonaws.com/prod/assets/images/header-flu.svg) no-repeat center center / cover',
        '--header-bg': '#ff5502',
        // '--header-mobile-bg': 'url(https://fluminensefc.s3.amazonaws.com/prod/assets/images/header-flu.svg) no-repeat center center / auto',
        '--header-mobile-bg': '#ff5502',
        '--header-mobile-logo-width': '100px',
        '--header-desktop-logo-width': '240px',
        '--header-mobile-height': '102px',
        '--header-desktop-height': '186px',
        '--header-mobile-font-size': '14px',
        '--header-counter-font-family': 'Roboto',
        '--header-counter-text-color': '#ff5502',
        '--header-counter-number-font-family': 'Roboto',
        '--header-counter-number-outline-color': 'none',
        '--header-desktop-font-size': '16px',
        '--header-mobile-margin-top': '0',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--public-header-security-top-padding': '0',
        '--private-header-security-top-padding': '0',
        '--header-counter-background': '#FFFFFF',
        '--header-counter-number-bg': '#946CB1',
        '--header-logged-bg': '#946CB1',
        '--header-logged-mobile-logo-width': '100px',
        '--header-logged-desktop-logo-width': '240px',

        // Home Public
        // Call to Action
        '--home-call-to-action-background': 'no-repeat center right / cover',
        '--home-call-to-action-background-img': 'url(https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/bg-home.png)',
        '--home-call-to-action-font-family': 'Roboto',
        '--home-call-to-action-background-opacity': '1',
        '--home-call-to-action-detail-color': '#fff',
        '--home-call-to-action-title-font-size': '75px',
        '--home-call-to-action-title-font-family': 'Roboto',
        '--home-call-to-action-title-desktop-max-width': '870px',
        '--home-call-to-action-title-font-weight': 'bold',
        '--home-call-to-action-title-margin': '0 auto 54px',
        '--home-call-to-action-title-line-height': '120%',
        '--home-call-to-action-title-alternate-font-family': 'VarsityTeam-Bold',
        '--home-call-to-action-title-alternate-font-size': '110px',
        '--home-call-to-action-subtitle-font-size': '21px',
        '--home-call-to-action-subtitle-line-height': '28px',
        '--home-call-to-action-subtitle-width': '500px',
        '--home-call-to-action-content-padding': '2% 0',
        '--home-call-to-action-content-align': 'center',
        '--home-call-to-action-button-margin': '37px',
        '--home-call-to-action-button-color': '#ff5502',
        '--home-call-to-action-hover-button-color': 'var(--background)',
        '--home-call-to-action-button-background': 'var(--background)',
        '--home-call-to-action-button-padding': '15px 64px',
        '--home-call-to-action-button-font-size': '19px',
        '--home-call-to-action-button-font-family': 'Ernesto',
        '--home-call-to-action-button-style': 'normal',
        '--home-call-to-action-margin-button': '50px 0 0',
        '--home-call-to-action-9-primary-font-family': 'Roboto',
        '--home-call-to-action-9-primary-button-font-weight': '700',
        '--home-call-to-action-9-description-font-size': '50px;',
        '--home-call-to-action-9-descriptionn-line-height': '60px',
        '--home-call-to-action-9-description-text-align': 'start',
        '--mobile-home-call-to-action-title-margin': '0 auto 30px',
        '--mobile-home-call-to-action-content-padding': '0 10% 50px',
        '--mobile-home-call-to-action-title-font-size': '40px',
        '--mobile-home-call-to-action-title-line-height': '120%',
        '--mobile-home-call-to-action-title-alternate-font-family': 'VarsityTeam-Bold',
        '--mobile-home-call-to-action-title-alternate-font-size': '62px',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',

        // Tabs
        '--home-desktop-tabs-1-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-1-background-img': '#F8F8F8',
        '--home-desktop-tabs-2-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-2-background-img': '#F8F8F8',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': '#F8F8F8',
        '--home-desktop-tabs-9-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-9-background-bg': 'url(https://www.fengbrasil.com.br/assets/images/particle.png)',
        '--home-desktop-tabs-9-background-bg-shield': 'url(https://www.fengbrasil.com.br/assets/images/particle.png)',
        '--home-desktop-tabs-9-background-bg-flag':'url(https://www.fengbrasil.com.br/assets/images/particle.png)',
        '--home-desktop-tabs-9-button-color': '#ff5502',
        '--home-desktop-tabs-9-title-font-family': 'Roboto',
        '--home-desktop-tabs-9-primary-font-family': 'Roboto',
        '--home-desktop-tabs-9-primary-button-font-weight': '500',
        '--home-desktop-tabs-9-description-font-size': '50px;',
        '--home-desktop-tabs-9-descriptionn-line-height': '0',
        '--home-desktop-tabs-9-description-text-align': 'center',


        // Home Private
        '--home-private-padding-top': '0',
        '--home-private-featured-height-tablet': '248px',
        '--home-private-featured-height-mobile-410': '133px',
        '--home-private-featured-height-mobile-380': '122px',
        '--home-private-plan-box-title-color': '#ff5502',
        '--home-private-cards-title': '#ff5502',

        // Plans
        '--plans-background': '#f8f8f8',

        // My Profile
        '--my-profile-title-color': '#ff5502',
        '--my-profile-name-color': '#ff5502',
        '--my-profile-subtitle-color': '#ff5502',
        '--my-profile-card-font-weight': '300',
        '--my-profile-text': '#27292D',
        '--my-profile-button': '#ff5502',
        '--my-profile-header': '#FFE794',

        // Contact
        '--contact-background': '#ff5502',

        // Experiences
        '--experience-how-to-score-icon-filter': '',

        //Experiences V2.0
        '--v2-experience-cta-bg': 'url(https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/hero-banner-web.png) center no-repeat',
        '--v2-experience-scroll-bg-1-2': 'url(https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/bg-scroll-2-e-3-web.png) center no-repeat',
        '--v2-experience-scroll-how-bg': 'url(https://flamengo.s3.sa-east-1.amazonaws.com/prod/assets/images/flaexperience/bg-scroll-4-web.png) center no-repeat',

        // Embassies
        '--embassies-table-image-size': '60px',
        '--embassies-title-color': '#ff5502',
        '--embassies-divider-color': '#f8f8f8',
        '--embassies-text-color': '#393939',

        // Exclusive Content
        '--exclusive-content-background-RGB': '0,0,0',
        '--exclusive-content-background': 'white',
        '--exclusive-content-text': 'var(--primary)',

        // MY PACKAGES
        '--package-title-font-family': 'Barlow',
        '--package-title-title-font-size': '32px',
        '--filter-color': '#727272',
        '--filter-active-background': '#FBF9FF',
        '--thumb-image-radius': '10px',
        '--thumb-mobile-width': '100%',
        '--thumb-mobile-height': '158px',
        '--thumb-desktop-width': '183px',
        '--thumb-desktop-height': '100px',
        '--event-date-font-size': '16px',
        '--event-date-font-size-interest': '14px',
        '--event-name-font-size': '20px',
        '--event-name-font-size-interest': '16px',
        '--event-name-text-transform': 'uppercase',
        '--event-addres-font-size': '14px',
        '--print-button-background': '#171717',
        '--print-button-color': '#fff',
        '--modal-info-banner-text-mobile-color': 'var(--primary-light)',
        '--modal-info-banner-text-desktop-color': '#fff',

        // Events
        '--event-background-select': '#946CB1',
        '--event-background-feature': '#946CB1',
        '--event-background-table': '#f8f8f8',
        '--event-color-button': '#ff5502',
        '--event-color-button-primary': '#ff5502',
        '--event-color-button-secondary': '#ff5502',
        '--event-transparent-button': '#ff5502',
        '--event-button-border-color': '#f8f8f8',
        '--event-color-text-button': '#ffffff',
        '--event-color-text-default': '#f8f8f8',
        '--event-color-text-light': '#f8f8f8',
        '--event-color-text-dark': '#ff5502',
        '--event-color-text-primary': '#000',
        '--event-color-text-secondary': '#ff5502',

        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': '#ffffff',
        '--footer-background': '#ff5502',
        '--footer-logo-width': '150px',

        // Census
        '--census-bg-frame': 'center / cover repeat url("https://ngx-feng.s3.sa-east-1.amazonaws.com/images/modules/LOY/census/bg-moldura-censo.png")',
        '--census-intro-bg': 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0) 100%), lightgray 50% center / cover no-repeat url("https://ngx-feng.s3.sa-east-1.amazonaws.com/images/modules/LOY/census/bg-censo-intro.png")',
        '--census-register-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat url("https://ngx-feng.s3.sa-east-1.amazonaws.com/images/modules/LOY/census/bg-censo-process.png")',
        '--census-token-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat url("https://ngx-feng.s3.sa-east-1.amazonaws.com/images/modules/LOY/census/bg-censo-intro.png")',
        '--census-research-questions-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat url("https://ngx-feng.s3.sa-east-1.amazonaws.com/images/modules/LOY/census/bg-censo-process.png")',
        '--census-research-success-bg': 'linear-gradient(0deg, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0) 100%), center / cover no-repeat url("https://ngx-feng.s3.sa-east-1.amazonaws.com/images/modules/LOY/census/bg-censo-sucess.png")',
        '--census-carousel-bg': 'linear-gradient(0deg, rgba(0, 0, 0, 0.00) 12.16%, rgba(0, 0, 0, 0.90) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), center / cover no-repeat url("https://ngx-feng.s3.sa-east-1.amazonaws.com/images/modules/LOY/census/Imagem-Scroll-Beneficios.png")',

        // Auth Modal
        '--auth-modal-stlogo-width': '180px',
        '--auth-modal-stlogo-bg': '#ff5502',
        '--auth-modal-closebtn-color': 'black',
        '--mobile-auth-modal-closebtn-color': 'white',
        '--mobile-auth-modal-stlogo-width': '100px',
        '--mobile-auth-modal-stlogo-margin': '10px 0',
        '--first-access-bg': '#ff5502',

        // Pending E-mail
        '--pending-mail-bg': 'white',
        '--pending-mail-color': 'black',
        '--pending-mail-logo-height': '100px',
        '--pending-mail-logo-bg': '#00321c',

        // Global texts colors
        "--text-global-white": "#ffffff",
        "--text-global-100": "#fafafa",
        "--text-global-200": "#f4f4f4",
        "--text-global-300": "#efefef",
        "--text-global-400": "#e9e9e9",
        "--text-global-500": "#e4e4e4",
        "--text-global-600": "#ababab",
        "--text-global-700": "#727272",
        "--text-global-800": "#393939",
        "--text-global-900": "#171717",
        "--text-global-black": "#000000",

        // Staging
        '--staging-logo-background-color': '#ff5502',

        // Maintenance
        '--maintenance-logo-background-color': '#ff5502',
    },
    images: {
        'stLogo': 'https://img.irroba.com.br/fit-in/600x600/filters:fill(fff):quality(80)/estampar/catalog/painel-redondo/painel-tardezinha-min.jpg',
        'stLogoMobile': 'https://img.irroba.com.br/fit-in/600x600/filters:fill(fff):quality(80)/estampar/catalog/painel-redondo/painel-tardezinha-min.jpg',
        'stLogoFooter': 'https://img.irroba.com.br/fit-in/600x600/filters:fill(fff):quality(80)/estampar/catalog/painel-redondo/painel-tardezinha-min.jpg',
        'loadingLogo': 'https://img.irroba.com.br/fit-in/600x600/filters:fill(fff):quality(80)/estampar/catalog/painel-redondo/painel-tardezinha-min.jpg',
        'stShield': 'https://img.irroba.com.br/fit-in/600x600/filters:fill(fff):quality(80)/estampar/catalog/painel-redondo/painel-tardezinha-min.jpg',
        '--homePlansBG': '#ff5502',
        '--homeTicketsBG': '#ff5502',
        '--homeExclusiveContentBG': '#ff5502',
        'mailPendingLogo': 'https://img.irroba.com.br/fit-in/600x600/filters:fill(fff):quality(80)/estampar/catalog/painel-redondo/painel-tardezinha-min.jpg',
    },
    texts: {
        // Home
        // Call to Action
        'callToActionFeatured': '<strong>Tardezinha</strong>',
        'callToActionFeaturedImg': '',
        'callToActionDetail': '"Agora eu me tornei a morte, a destruidora de mundos." - Robert Oppenheimer',
        // 'callToActionButton': 'SEJA SÓCIO',

        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este é o seu primeiro acesso ao nosso novo sistema. Para continuar, você deve aceitar o Termos de Uso e Política de Privacidade. Aproveite também para atualizar os seus dados cadastrais!',
        // Recover Pass
        'recoverPassText': 'Para definir a sua nova senha, digite o código de recuperação recebido por e-mail, além de sua nova senha.',

        // Ticket Cards
        'ticketCardText': 'Cartão de Sócio',
        'ticketCardsText': 'MEU CARTÃO-INGRESSO'
    }
}