import { IFooter } from '../interfaces';

export const FOOTER: IFooter = {
  footerTemplate: 1,
  social: [
    { icon: 'facebook', href: 'https://www.facebook.com/fengbrasil' },
    { icon: 'twitter', href: 'https://twitter.com/ECfengbrasil' },
    { icon: 'youtube', href: 'https://www.youtube.com/channel/UCrY4s3Eq7zSa5SE1LjoW_xg' },
    { icon: 'instagram', href: 'https://www.instagram.com/ecfengbrasil' }
  ],
  nav: [
    { title: 'HOME', routerLink: '/', click: '', clickParam: '', if: 'notHome' },
    { title: 'PLANOS', routerLink: '/planos', click: '', clickParam: '', if: '' },
    { title: 'INGRESSOS', routerLink: '/ingressos', click: '', clickParam: '', if: '' },
    { title: 'EMBAIXADAS', routerLink: '/embaixadas', click: '', clickParam: '', if: '' },
    { title: 'ATENDIMENTO', routerLink: '/atendimento', click: '', clickParam: '', if: '' },
    { title: 'ASSOCIE-SE', routerLink: '/checkout/cart', click: '', clickParam: '', if: '' },
    { title: 'LOGOUT', routerLink: '', click: 'logout', clickParam: '', if: 'isAuth' },
  ],
  enableTerms: true
}
